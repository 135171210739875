import ContactPipeline from "./ContactPipeline";
import ContactMilestone from "./ContactMilestone";
import { useContacts } from "../../../app/hooks/useContacts";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import ContactFilter from "./ContactFilter";
import { OMSDiaryNoteItem } from "../../../app/common/common-components/OMSDiaryNoteComponents/OMSDiaryNoteItem";
import { useRef, useState } from "react";
import { useStore } from "../../../app/stores/store";

const ContactBoard = () => {
    const {
        contactInfo = {},
        handleClickSendEmail = () => { },
        handleContactViewTypeChange = () => { },
        handleDragEnd = () => { },
        handleOnClickMenu = () => { },
        handleSearchClick = () => { },
        handleTagsChange = () => { },
        handleViewTypeChange = () => { },
        handleWhatsappClick = () => { },
        isLoading = true,
        loadDiaryAjaxForContact=()=>{},
        milestoneMaxHeight = 0,
        tagColorFormatter = () => { },
    } = useContacts();

    const contactType = contactInfo.selectedContactView;
    const viewType = contactInfo.selectedViewType;

    const noteItemRef = useRef();

    const [currentContactId, setCurrentContactId] = useState("");
    const {
        userStore: { userSession, logout, logoutAs, isLoggedIn },
    } = useStore();
    
    const userId = userSession?.userId;
  

    const {
        officeTags = [],
        sourceTypesTag = [],
        userTags = [],
    } = contactInfo;

    const {
        actions = [],
        diaryActionTags = [],
        pipelines = [],
        selectedPipelineId,
        stages = [],
    } = contactInfo;

    const handleClickNewActivity = (contactId) => {
        setCurrentContactId(contactId);
        noteItemRef.current.onOpenDairyNoteItem(null);
    }
    const handleDiaryItemSubmitCompleteFunc=()=>{
        loadDiaryAjaxForContact();        
    }
    // Conditional rendering based on loading state
    if (isLoading) {
        return <AjaxLoader />
    }
    return (
        <>

            <div className="contact-main-component">

                <div className="col-md-2">
                    <div className="menu-item contact-section">
                        <ContactPipeline
                            onClickMenu={handleOnClickMenu}
                            pipelines={pipelines}
                            selectedPipeline={selectedPipelineId}
                        />
                    </div>
                </div>
                <div className="col-md-10">
                    <div className="contact-wrapper-section contact-section">
                        <div className="filter-section">
                            {

                                <ContactFilter
                                    actions={actions}
                                    contactType={contactType}
                                    officeTags={officeTags}
                                    onChangeContactViewType={handleContactViewTypeChange}
                                    onChangeTags={handleTagsChange}
                                    onChangeViewType={handleViewTypeChange}
                                    onClickSeach={handleSearchClick}
                                    sourceTypesTag={sourceTypesTag}
                                    userTags={userTags}
                                    viewType={viewType}
                                />
                            }
                        </div>
                        {stages && stages.length > 0 && (
                            <div className="contact-draggable-area">
                                <ContactMilestone
                                    actions={actions}
                                    milestoneMaxHeight={milestoneMaxHeight}
                                    officeTags={officeTags}
                                    onClickSendEmail={handleClickSendEmail}
                                    onClickNewActivity={handleClickNewActivity}
                                    onDragEnd={handleDragEnd}
                                    onWhatsappClick={handleWhatsappClick}
                                    stages={stages}
                                    tagColorFormatter={tagColorFormatter}
                                    userTags={userTags}
                                    viewType={viewType}

                                />


                                <OMSDiaryNoteItem
                                    DiaryActionTags={diaryActionTags}
                                    DNType={1}
                                    htmlID="noteItem-contact"
                                    ID={currentContactId}
                                    IsReadOnly={(actions.indexOf('create') < 0)}
                                    OnShowEnd={userSession?.headerValues?.onShowEnd}
                                    OnShowStart={userSession?.headerValues?.onShowStart}
                                    onDiaryItemSubmitCompleteFunc={handleDiaryItemSubmitCompleteFunc}
                                    ref={noteItemRef}
                                    UserID={userId}
                                />


                            </div>)
                        }
                        {
                            stages.length == 0 && (
                                <div class="message-container">
                                    <h4>There are no milestones in the pipeline. Please configure the pipeline with milestones to ensure it works correctly.</h4>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactBoard;