import React, { useCallback, useEffect, useState } from 'react'
import apiAgent from '../api/apiAgent';

export const useContacts = () => {

    const [contactInfo, setContactInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [milestoneMaxHeight, setMilestoneMaxHeight] = useState(0);
    const [contactUpdater, setContactUpdater] = useState(0);

    const getStageInfoAPI = (pipelineId, viewType, contactviewType) => {
        let stageResponse = apiAgent.Contact.contactsInfoAjax(
            pipelineId,
            contactInfo.selectedTags,
            contactviewType,
            viewType,
            contactInfo.searchTerm
        );
        return stageResponse;

    }
    const getContactInfoAPI = async () => {
        let contactResponse = await apiAgent.Contact.getContacts();
        return contactResponse;
    }
    const loadDiaryAjaxForContact = useCallback(async ()=>{
        let stageResponse = await getStageInfoAPI(contactInfo.selectedPipelineId, contactInfo.selectedViewType, contactInfo.selectedContactView);

        const {
            stages = [],
        } = stageResponse;

        setContactInfo((prev)=>({
            ...prev,
            stages: [...stages]

        }))
    });


    const fetchContact = useCallback(async () => {
        setIsLoading(true);
        let contactResponse = await getContactInfoAPI();

        const {
            actions = [],
            contactPipelines = [],
            contactSourceTypes = [],
            contactviewType = 0,
            diaryActionTags = [],
            officeContactTags = [],
            userContactTags = [],
            workspaceType = 0,
            workspacePipelineId = ""
        } = contactResponse;

        let stageResponse = await getStageInfoAPI(workspacePipelineId == "" ? contactPipelines[0].value : workspacePipelineId, workspaceType, contactviewType)


        const {
            contactCount = 0,
            pipelineID = "",
            stages = [],
        } = stageResponse;

        setContactInfo({
            actions: [...actions],
            contactCount: contactCount,
            diaryActionTags: [...diaryActionTags],
            officeTags: [...officeContactTags],
            pipelines: [...contactPipelines],
            selectedPipelineId: pipelineID,
            sourceTypesTag: [...contactSourceTypes],
            stages: [...stages],
            userTags: [...userContactTags],
            selectedViewType: workspaceType,
            selectedContactView: contactviewType
        });
        if (Object.entries(contactResponse).length > 0) {
            setIsLoading(false);
            setContactUpdater(contactUpdater + 1);
        }

    }, []);

    const handleTagsChange = useCallback(async (e) => {
        let stageResponse = await apiAgent.Contact.contactsInfoAjax(
            contactInfo.selectedPipelineId,
            e,
            contactInfo.selectedContactView,
            contactInfo.selectedViewType,
            contactInfo.searchTerm
        );
        const {
            contactCount = 0,
            pipelineID = "",
            stages = [],
        } = stageResponse;

        setContactInfo((prev) => ({
            ...prev,
            stages: [...stages],
            contactCount: contactCount,
            selectedTags: e

        }));

        setContactUpdater(contactUpdater + 1);

    });
    const handleContactViewTypeChange = useCallback(async (type) => {

        let stageResponse = await apiAgent.Contact.contactsInfoAjax(
            contactInfo.selectedPipelineId,
            contactInfo.selectedTags,
            type,
            contactInfo.selectedContactView,
            contactInfo.searchTerm
        );
        const {
            contactCount = 0,
            pipelineID = "",
            stages = [],
        } = stageResponse;

        setContactUpdater(contactUpdater + 1);
        setContactInfo((prev) => ({
            ...prev,
            stages: [...stages],
            contactCount: contactCount,
            selectedContactView: type,
        }));
    });

    const handleViewTypeChange = useCallback(async (type) => {

        let stageResponse = await apiAgent.Contact.contactsInfoAjax(
            contactInfo.selectedPipelineId,
            contactInfo.selectedTags,
            contactInfo.selectedContactView,
            type,
            contactInfo.searchTerm
        );
        const {
            contactCount = 0,
            pipelineID = "",
            stages = [],
        } = stageResponse;

        setContactInfo((prev) => ({
            ...prev,
            selectedViewType: type

        }));

        setContactUpdater(contactUpdater + 1);

    });
    const handleSearchClick = useCallback(async (searchTerm) => {
        let stageResponse = await apiAgent.Contact.contactsInfoAjax(
            contactInfo.selectedPipelineId,
            contactInfo.selectedTags,
            contactInfo.selectedContactView,
            contactInfo.selectedViewType,
            searchTerm);
        const {
            contactCount = 0,
            pipelineID = "",
            stages = [],
        } = stageResponse;

        setContactInfo((prev) => ({
            ...prev,
            stages: [...stages],
            contactCount: contactCount,
            searchTerm: searchTerm,
        }));

    });

    const handleDragEnd = useCallback(async (result) => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
        const draggableContact = contactInfo.stages
            .find((stage) => stage.stageID === source.droppableId)
            .contacts.find((contact) => contact.contactID === draggableId);

        const updatedData = [...contactInfo.stages];

        // Stage Index

        const sourceStageIndex = updatedData.findIndex((stage, i) => stage.stageID === source.droppableId);
        const destinationStageIndex = updatedData.findIndex((stage, i) => stage.stageID === destination.droppableId);

        // Contact Index

        const sourceContactIndex = updatedData[sourceStageIndex].contacts
            .findIndex((contact) => contact.contactID === draggableId);

        const destinationContactIndex = destination.index;

        updatedData[sourceStageIndex].contacts.splice(sourceContactIndex, 1);
        updatedData[destinationStageIndex].contacts.splice(destinationContactIndex, 0, draggableContact);

        setContactUpdater(contactUpdater + 1);
        let stageDragResult = await apiAgent.Contact.contactStageChangeAjax(result.draggableId, destination.droppableId);

    });

    const getMilestoneBoxHeight = (index) => {
        let element = undefined;
        let height = 0;
        element = document.getElementById(`drag_drop_container_${index}`);

        if (element) {
            height = element ? element.clientHeight : 0;
        }
        return height;
    };

    const getMaxMilestoneBoxHeight = (numberOfElements) => {
        let maxHeight = 0;
        for (let i = 0; i < numberOfElements; i++) {
            const elementHeight = getMilestoneBoxHeight(i);
            if (elementHeight > maxHeight) {
                maxHeight = elementHeight;
            }
        }
        return maxHeight;
    };

    const handleOnClickMenu = useCallback(async (menuId) => {
        let stageResponse = await apiAgent.Contact.contactsInfoAjax(
            menuId,
            contactInfo.selectedTags,
            contactInfo.selectedContactView,
            contactInfo.selectedViewType,
            contactInfo.searchTerm
        );
        const {
            contactCount = 0,
            pipelineID = "",
            stages = [],
        } = stageResponse;

        setContactInfo((prev) => ({
            ...prev,
            stages: [...stages],
            contactCount: contactCount,
            selectedPipelineId: pipelineID

        }));
    });

    const handleClickSendEmail = (mailId) => {
        window.open(`mailto:${mailId}?subject=&body=`);
    };

    const tagColorFormatter = (value) => {
        if (value == undefined
            || value == ""
            || contactInfo.officeTags == undefined
            || contactInfo.userTags == undefined) return "";

        var splitValue = value;

        let tagList = new Set(
            splitValue?.map(tag => ({
                name: tag,
                color: "#494F56",
                isSelected: false
            }))
        );

        let TagArray = Array.from(tagList);

        const officeTagsMap = new Set(contactInfo?.
            officeTags?.
            map(tag => ({
                name: tag.name,
                color: tag.color,
                isSelected: false
            })));

        const userTagsMap = new Set(contactInfo?.
            userTags?.
            map(tag => ({
                name: tag.name,
                color: tag.color,
                isSelected: false
            })));

        const findTagByName = (set, tagName) => {
            return [...set].find(tag => tag.name === tagName);
        }
        return TagArray.map((tag, index) => {
            tag.isSelected = contactInfo?.selectedTags?.includes(tag.name);
            let tagColor = findTagByName(officeTagsMap, tag.name)?.color || findTagByName(userTagsMap, tag.name)?.color;
            let className = "label";
            tag.color = tag.isSelected ? tagColor : tag.color;
            return tagColor ? (<span
                key={index}
                className={className}
                style={{ backgroundColor: tag.color }}                >
                {tag.name}

            </span>
            ) : null
        });
    }


    const handleWhatsappClick = (phone) => {
        const url = `https://wa.me/${phone}`;
        window.open(url, "_blank", "noreferrer");
    }
    useEffect(() => {
        if (contactInfo?.stages?.length > 0) {
            const maxHeight = getMaxMilestoneBoxHeight(contactInfo.stages.length);
            setMilestoneMaxHeight(maxHeight);
        }
    }, [contactUpdater]);

    useEffect(() => {
        fetchContact();
    }, [])

    return {
        contactInfo,
        getMaxMilestoneBoxHeight,
        handleClickSendEmail,
        handleContactViewTypeChange,
        handleDragEnd,
        handleOnClickMenu,
        handleSearchClick,
        handleTagsChange,
        handleViewTypeChange,
        handleWhatsappClick,
        isLoading,
        loadDiaryAjaxForContact,
        milestoneMaxHeight,
        tagColorFormatter,
    }
}

