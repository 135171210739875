import { useCallback } from "react";

const ContactPipeline = ({
    onClickMenu = () => { },
    pipelines = [],
    selectedPipeline = "",
}) => {
    const isDefaultPipeline = () => {
        return (!selectedPipeline && selectedPipeline === "" && pipelines.length > 0)
    }
    const generateMenu = useCallback(() => {
        if (!Array.isArray(pipelines)) {
            return null;
        }
        return (
            <>
                {
                    isDefaultPipeline() &&
                    (
                        <>
                            <div className="pipeline-container">
                                <i className="fa fa-list-alt" style={{ fontSize: "14px" }}></i>
                                <span className={`dashboard-menu`}>
                                    <b> {pipelines[0].text}</b>
                                </span>
                            </div>
                        </>
                    )
                }
                {
                    isDefaultPipeline() &&
                    (
                        pipelines?.slice(1)?.map((pipeline) => {
                            return (
                                <>
                                    <div className="pipeline-container">
                                        <i className="fa fa-list-alt" style={{ fontSize: "14px" }}></i>

                                        <span
                                            key={pipeline.value}
                                            className={`dashboard-menu`}
                                            onClick={() => onClickMenu(pipeline.value)}
                                        >
                                            <i className="icon-class" style={{ marginRight: 8 }} />

                                            {pipeline.value === selectedPipeline ? (
                                                <b>{pipeline.text}</b>
                                            ) : (
                                                <span>{pipeline.text}</span>
                                            )}
                                        </span>
                                    </div>
                                </>
                            )
                        })
                    )
                }
                {
                    !isDefaultPipeline() &&
                    (
                        pipelines?.map((pipeline) => {

                            return (
                                <>
                                    <div className="pipeline-container">
                                        <i className="fa fa-list-alt" style={{ fontSize: "14px" }}></i>

                                        <span
                                            key={pipeline.value}
                                            className={`dashboard-menu`}
                                            onClick={() => onClickMenu(pipeline.value)}
                                        >
                                            <i className="icon-class" style={{ marginRight: 8 }} />

                                            {pipeline.value === selectedPipeline ? (
                                                <b>{pipeline.text}</b>
                                            ) : (
                                                <span>{pipeline.text}</span>
                                            )}
                                        </span>
                                    </div>

                                </>
                            )
                        })
                    )
                }
            </>
        )
    });

    return (
        <>
            <div className="container-menu-link">
                {/* Menu for Small Screens */}
                <div className="menu-small col-sm-2">
                    <div style={{ padding: "10px 10px 0px 0px" }} className="menu-container">
                        {generateMenu()}
                    </div>
                </div>

                {/* Menu for Medium Screens and up */}
                <div className="menu-large col-md-1">
                    <div style={{ padding: "10px 10px 0px 0px" }} className="menu-container">
                        {generateMenu()}
                    </div>
                </div>
            </div>


        </>
    );
}

export default ContactPipeline;