import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ContactCard from "./ContactCard";

const ContactMilestone = ({
    actions = [],
    milestoneMaxHeight = 0,
    onClickSendEmail = () => { },
    onDragEnd = () => { },
    onWhatsappClick = () => { },
    stages = [],
    tagColorFormatter = () => { },
    onClickNewActivity = () => { },
    viewType = 0,

}) => {

    return (
        <>
            <div className="milestone-drag-container mt-2">
                <DragDropContext onDragEnd={onDragEnd}>
                    {stages?.length > 0
                        && stages?.map((stage, index) => {

                            return (
                                <>
                                    <div className="milestone-column" key={stage.stageID}>
                                        <Droppable droppableId={stage.stageID}>
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <div className="column-wrapper sticky-milestone-header" key={index}>
                                                        <div className="column-header-wrapper">
                                                            <span className="milestone-title">{stage?.stageName}</span>
                                                            <span className="milestone-badge badge">{stage?.contacts?.length}</span>
                                                        </div>
                                                    </div>
                                                    <div className={`column-body-wrapper ${snapshot.isDraggingOver ? 'dragging-milestone' : ''}`} style={{ height: milestoneMaxHeight + "px" }}>
                                                        <div className="dragDropInner" id={`drag_drop_container_${index}`}>
                                                            {stage.contacts.map((contactItem, index) => {
                                                                return (
                                                                    <Draggable key={contactItem.contactID}
                                                                        draggableId={contactItem.contactID}
                                                                        index={index}
                                                                    >
                                                                        {(provided) => (
                                                                            <>
                                                                                <ContactCard
                                                                                    contactItem={contactItem}
                                                                                    onClickSendEmail={onClickSendEmail}
                                                                                    onWhatsappClick={onWhatsappClick}
                                                                                    onClickNewActivity={onClickNewActivity}
                                                                                    provided={provided}
                                                                                    snapshot={snapshot}
                                                                                    tagColorFormatter={tagColorFormatter}
                                                                                    viewType={viewType}
                                                                                    index={index}
                                                                                />

                                                                            </>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            })}
                                                            <div style={{ height: "100px" }}></div>
                                                        </div>
                                                    </div>
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>

                                    </div>


                                </>
                            )
                        })
                    }
                </DragDropContext>
            </div>
        </>
    );
}

export default ContactMilestone
