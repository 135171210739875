import React, { useEffect, useState, useRef } from "react";
import { OMSButtonStyleL2 } from "../../../../app/common/common-components/OMSButton";
import apiAgent from "../../../../app/api/apiAgent";
import FuzzySearchResultsGrid from "./FuzzySearchResultsGrid";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css"; // Import Tippy.js CSS for default styling
import PropertyReportsStore from "../../../../app/stores/propertyReportsStore";
import { observer } from "mobx-react-lite";
import { PropertyReportModel } from "../../../../app/models/PropertyReportModel";
import { toast } from "react-toastify";
import KeywordSearchComponent from "./KeywordSearchComponent";
import { OMSTabBox } from "../../../../app/common/common-components/OMSTabBox";
import PropertySearchControls from "./PropertySearchControls";

interface Props {
  propertyReportsStore: PropertyReportsStore;
}

const PropertySearchComponent: React.FC<Props> = observer(({ propertyReportsStore }) => {
  const [inputAddress, setInputAddress] = useState("");
  const [inputId, setInputId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState<PropertyReportModel[]>([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [existingReports, setExistingReports] = useState<PropertyReportModel[]>([]);
  const [activeTabID, setActiveTabID] = useState("propertySearchControls");
  const [selectedSearchType, setSelectedSearchType] = useState("propertySearch");
  const tooltipRef = useRef<HTMLElement>(null);

  const [searchParams, setSearchParams] = useState({
    provinceId: propertyReportsStore.provinceId,
    town: "",
    suburb: "",
    street: "",
    streetNumber: "",
    unitNumber: "",
  });

  const tooltipContent = `
    <div style="text-align: left;">
      <p>Search for property or owner details using any of the following:</p>
      <ul style="padding-left: 20px;">      
        <li>Property Address or part thereof.</li>
        <li>Erf number, Portion number, and Property Name. Ideal for farms who don't always have street addresses.</li>
        <li>Unit Number Property Name. E.g. "1 Chancliff", ideal for sectional schemes.</li>
        <li>Title Deed Number or part thereof.</li>
        <li>Owner surname or part of the owner name.</li>
      </ul>
    </div>`;

  const handleSearchParamsChange = (key: string, value: string) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      [key]: value,
    }));
  };

  const validateFields = () => {
    const { street, town, suburb } = searchParams;
    if (!street || street.trim() === "" || (!town && !suburb)) {
        toast.error("Please enter Street along with either Town or Suburb");
        return false;
    }
    return true;
};


  useEffect(() => {
    apiAgent.PropertyReports.getExistingReportsForUser()
      .then((response) => {
        setExistingReports(response);
      })
      .catch(() => {
        setIsLoading(false);
        setSearchPerformed(true);
        toast.error("Some error occurred. Please refresh this page.");
      });
  }, []);

  useEffect(() => {
    // Initialize tooltip with preventOverflow and zIndex settings
    let tippyInstance: any;

    if (tooltipRef.current) {
      tippyInstance = tippy(tooltipRef.current, {
        content: tooltipContent,
        allowHTML: true,
        placement: "bottom",
        trigger: "mouseenter focus click",
        theme: "custom", // Apply custom theme
        interactive: true,
        appendTo: document.body, // Ensure tooltip is attached to body to avoid scroll issues
        zIndex: 9999, // Bring tooltip to the front
        popperOptions: {
          modifiers: [
            {
              name: "preventOverflow",
              options: {
                boundary: "viewport",
              },
            },
          ],
        },
      });
    }

    // Cleanup function
    return () => {
      if (tippyInstance) {
        tippyInstance.destroy();
      }
    };
  }, [tooltipRef.current, selectedSearchType]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputAddress(event.target.value);
  };

  const handleIdInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputId(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  const handleSearchTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSearchType(event.target.value);
    setProperties([]);
    setSearchPerformed(false);
  };

  const toTitleCase = (str: string) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleClick = () => {
    setIsLoading(true);
    setSearchPerformed(false);

    if (selectedSearchType === "propertySearch") {
      //console.log("Search Params", searchParams);
      if (validateFields()) {
        apiAgent.PropertyReports.propertySearch(searchParams)
          .then((response: any) => {
            //console.log("Property Search Response:", response);
            const formattedResponse: PropertyReportModel[] = response.map((property: any) => {
              const streetAddressParts = [
                property.unitNumber && property.unitNumber !== "0" ? property.unitNumber : "",
                property.propertyName || "",
                [property.streetNumber, property.streetName, property.streetType].filter(Boolean).join(" "),
                property.erf ? `Erf: ${property.erf}` : "",
              ];

              const formattedStreetAddress = streetAddressParts
                .map(toTitleCase)
                .filter((part) => part !== "Full Title")
                .filter(Boolean)
                .join(", ")
                .trim();

              return {
                propertyId: property.propertyId,
                streetAddress: formattedStreetAddress,
                propertyType: property.propertyType,
                existingReport: property.existingReport,
                reportGenerationDate: property.reportGenerationDate,
                reportId: property.reportId,
                ownerDetails: property.ownerDetails,
                ownerDetailsGenerationDate: property.ownerDetailsGenerationDate,
                propertyDetails: {
                  province: property.province,
                  town: property.town,
                  suburb: property.suburb,
                  streetName: property.streetName,
                  streetNumber: property.streetNumber,
                  streetType: property.streetType,
                  unitNumber: property.unitNumber,
                  erf: property.erf,
                  portion: property.portion,
                  ext: property.ext,
                  extDeeds: property.extDeeds,
                  deedsOffice: property.deedsOffice,
                  propertyName: property.propertyName,
                  latitude: property.latitude,
                  longitude: property.longitude,
                  standSize: property.standSize,
                  valuationDate: property.valuationDate,
                  valuationValue: property.valuationValue,
                  valuationZoning: property.valuationZoning,
                  transferId: property.transferId,
                },
              };
            });

            propertyReportsStore.setSearchedPropertyReports(formattedResponse);
            setProperties(formattedResponse);
            setIsLoading(false);
            setSearchPerformed(true);
          })
          .catch((error: any) => {
            setIsLoading(false);
            setSearchPerformed(true);
            toast.error("Some error occurred during property search. Please try again.");
            console.log("Error during property search", error);
          });
      } else {
        setIsLoading(false);
      }
    } else if (selectedSearchType === "idSearch") {
      //console.log("ID Search Input:", inputId);
      if (inputId.trim() === "") {
        toast.error("Please enter the ID for ID Search.");
        setIsLoading(false);
        return;
      }
      apiAgent.PropertyReports.idSearch(inputId)
        .then((response) => {
          //console.log("ID Search Response:", response);
          const formattedResponse: PropertyReportModel[] = response.map((property: any) => ({
            propertyId: property.propertyId,
            streetAddress: property.streetAddress.split("|").map(toTitleCase).join(" "),
            propertyType: property.propertyType,
            existingReport: property.existingReport,
            reportGenerationDate: property.reportGenerationDate,
            reportId: property.reportId,
            ownerDetails: property.ownerDetails,
            ownerDetailsGenerationDate: property.ownerDetailsGenerationDate,
          }));

          propertyReportsStore.setSearchedPropertyReports(formattedResponse);
          setProperties(formattedResponse);
          setIsLoading(false);
          setSearchPerformed(true);
        })
        .catch((error: any) => {
          setIsLoading(false);
          setSearchPerformed(true);
          toast.error("Some error occurred during search. Please try again.");
          console.log("Error during fuzzy search", error);
        });
    } else {
      if (inputAddress.trim() === "") {
        toast.error("Please enter the keyword.");
        setIsLoading(false);
        return;
      }
      apiAgent.PropertyReports.keywordSearch(inputAddress)
        .then((response) => {
          //console.log("Keyword Search Response", response);
          const formattedResponse: PropertyReportModel[] = response.map((property: any) => ({
            propertyId: property.propertyId,
            streetAddress: property.streetAddress.split("|").map(toTitleCase).join(" "),
            propertyType: property.propertyType,
            existingReport: property.existingReport,
            reportGenerationDate: property.reportGenerationDate,
            reportId: property.reportId,
            ownerDetails: property.ownerDetails,
            ownerDetailsGenerationDate: property.ownerDetailsGenerationDate,
          }));

          propertyReportsStore.setSearchedPropertyReports(formattedResponse);
          setProperties(formattedResponse);
          setIsLoading(false);
          setSearchPerformed(true);
        })
        .catch((error: any) => {
          setIsLoading(false);
          setSearchPerformed(true);
          toast.error("Some error occurred during search. Please try again.");
          console.log("Error during fuzzy search", error);
        });
    }
  };

  return (
    <>
      <div>
        <div className="panel panel-default panel-property-reports oms-box">
          <div className="wrap">
            <div className="panel-heading">
              <div className="row">
                <div className="search-type-options" style={{ display: "flex", gap: "40px", marginLeft: "15px" }}>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="searchType"
                      value="propertySearch"
                      id="propertySearch"
                      checked={selectedSearchType === "propertySearch"}
                      onChange={handleSearchTypeChange}
                    />
                    <label className="form-check-label" htmlFor="propertySearch">
                      &nbsp; Property Search
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="searchType"
                      value="idSearch"
                      id="idSearch"
                      checked={selectedSearchType === "idSearch"}
                      onChange={handleSearchTypeChange}
                    />
                    <label className="form-check-label" htmlFor="idSearch">
                      &nbsp; ID Search
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="searchType"
                      value="keywordSearch"
                      id="keywordSearch"
                      checked={selectedSearchType === "keywordSearch"}
                      onChange={handleSearchTypeChange}
                    />
                    <label className="form-check-label" htmlFor="keywordSearch">
                      &nbsp; Keyword Search
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="panel-body" style={{ backgroundColor: "white" }}>
              {selectedSearchType === "propertySearch" && (
                <PropertySearchControls searchParams={searchParams} onSearchParamsChange={handleSearchParamsChange} />
              )}
              {selectedSearchType === "idSearch" && (
                <div className="form-group col-md-12" style={{ position: "relative" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      className="form-control required-control text-450"
                      id="idInput"
                      placeholder="Enter Owner ID"
                      value={inputId}
                      onChange={handleIdInputChange}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
              )}
              {selectedSearchType === "keywordSearch" && (
                <div className="form-group col-md-12" style={{ position: "relative" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      name="inputAddress"
                      className="form-control required-control text-450"
                      placeholder="Enter part of address, erf number, title deed number, ID number etc."
                      value={inputAddress}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      style={{ flex: 1 }}
                    />
                    <i ref={tooltipRef} className="fa fa-info-circle" style={{ marginLeft: "10px", color: "#337ab7", cursor: "pointer" }} aria-hidden="true" />
                  </div>
                </div>
              )}
            </div>

            <div className="panel-footer">
              <OMSButtonStyleL2
                type="button"
                style={{ marginRight: 10 }}
                ui_icon="fa-check"
                ui_type="save"
                ui_text={isLoading ? "Searching.." : "Search"}
                ui_processing_text="Searching.."
                disabled={isLoading}
                onClick={handleClick}
                processing={isLoading}
              />

              <i className="fa fa-question-circle" style={{ marginLeft: "10px", color: "#337ab7" }} aria-hidden="true"></i>
              <a href="https://help.entegral.net/en/articles/9890912-insights-overview" target="_blank" style={{ marginLeft: 5 }}>
                Get Started with Insights
              </a>
            </div>
          </div>
        </div>

        {searchPerformed && properties.length === 0 && (
          <div className="alert alert-warning" role="alert">
            No properties found.
          </div>
        )}

        {searchPerformed && properties.length > 0 && (
          <FuzzySearchResultsGrid isLoading={isLoading} propertyReportsStore={propertyReportsStore} selectedSearchType={selectedSearchType} />
        )}
      </div>
    </>
  );
});

export default PropertySearchComponent;
