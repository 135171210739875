import React, { useContext, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import dragContext from "../../DragStore/DragContext";
import { useHistory } from "react-router";
import AjaxLoader from "../../../../app/common/common-components/AjaxLoader";
import Progressbar from "../../common/Progressbar";
import OMSTooltip from "../../../../app/common/common-components/OMSTooltip/OMSTooltip";

const PipelineSales = ({
  pipelineDD,
  workspaceDD,
  currencySymbol,
  accessType,
  actions,
  filterData,
  loopFilterOpen,
  loopFilterChanged,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [pipelineDisplayData, setPipelineDisplayData] = useState([]);
  const state = useContext(dragContext);
  let history = useHistory();
  const [maxDealLength, setMaxDealLength] = useState(0);
  const [dragCardsHeight, setDragCardsHeight] = useState(0);
  const [pipelineBoxHeight, setPipelineBoxHeight] = useState(0);
  const [stageMaxHeight, setStageMaxHeight] = useState(0);
  const dragCardsRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    state.setFilterData(filterData);
    setSearch("");
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, [filterData]);

  useEffect(() => {
    // Check if the ref is set and get its client height
    if (dragCardsRef.current) {
      const clientHeight = dragCardsRef.current.clientHeight;
      setDragCardsHeight(clientHeight); // Update the state with the client height
    }
  }, []); // Run this effect only once on mount

  useEffect(() => {
    var mainData = JSON.parse(JSON.stringify(state.pipelineData));
    let maxDealsLength = 0;

    if (search) {
      var searchL = search.toLocaleLowerCase();
      mainData.forEach((element) => {
        var deals = [...element.deals];
        element.deals = deals.filter(function (e) {
          return e.dealName.toLowerCase().indexOf(searchL) != -1;
        });
      });
    }

    const timeoutId = setTimeout(() => {
      const maxHeight = getMaxPipelineBoxHeight(mainData.length);
      setStageMaxHeight(maxHeight);
    }, 2000);



    for (const stage of mainData) {
      if (stage.deals.length > maxDealsLength) {
        maxDealsLength = stage.deals.length;
      }
    }
    setMaxDealLength(maxDealsLength);
    setPipelineDisplayData(mainData);

    return () => clearTimeout(timeoutId); // Cleanup
  }, [search, state.pipelineData]);

  useEffect(() => {
    var pipelineBoxHeight = (maxDealLength * 120) + (240) - (200);
    setPipelineBoxHeight(pipelineBoxHeight);
  }, [maxDealLength]);

  const isDragCardScrollable = (dragCardHeight, pipelineBoxHeight) => {
    return dragCardHeight < pipelineBoxHeight;
  }

  const getPipelineBoxHeight = (index) => {
    let element = undefined;
    let height = 0;
    element = document.getElementById(`drag_drop_container_${index}`);
    if (element) {
      height = element ? element.clientHeight : 0;
    }
    return height;
  };

  const getMaxPipelineBoxHeight = (numberOfElements) => {
    let maxHeight = 0;
    // Loop through each element based on index
    for (let i = 0; i < numberOfElements; i++) {
      const elementHeight = getPipelineBoxHeight(i); // Get the height of the current element

      // Update maxHeight if the current element's height is greater
      if (elementHeight > maxHeight) {
        maxHeight = elementHeight;
      }
    }
    return maxHeight; // Return the maximum height found
  };

  const handleWorkspaceDDChange = (wId, wName) => {
    var mFilterData = filterData.getCopy();
    mFilterData.WorkspaceAgentId = wId;
    mFilterData.WorkspaceAgentName = wName;
    mFilterData.NeedUpdate = true;
    loopFilterChanged(mFilterData);
  };

  const handlePipelineChange = (pId, pName) => {
    var mFilterData = filterData.getCopy();
    mFilterData.WorkspacePipelineId = pId;
    mFilterData.WorkspacePipeline = pName;
    mFilterData.NeedUpdate = true;
    loopFilterChanged(mFilterData);
  };

  const getAmountFormat = (number) => {
    if (isNaN(number)) return "0";

    var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
    var tier = (Math.log10(Math.abs(number)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier == 0) return number.toFixed(1);

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  };

  const getAllDealAmount = (deals, t) => {
    var totalAmount = 0;
    for (var i = 0; i < deals.length; i++) {
      if (t == "P") {
        totalAmount += isNaN(deals[i].purchaseAmount)
          ? 0
          : deals[i].purchaseAmount;
      } else {
        totalAmount += isNaN(deals[i].grossNettCommision)
          ? 0
          : deals[i].grossNettCommision;
      }
    }
    return totalAmount;
  };

  const getAllDealRedPercentage = (deals) => {
    var totalTasks = 0;
    var completedTasks = 0;
    for (var i = 0; i < deals.length; i++) {
      totalTasks += deals[i].totalTasks;
      completedTasks += deals[i].completedTaskRed;
    }

    if (totalTasks == 0) return 0;
    else return (completedTasks * 100) / totalTasks;
  };

  const getAllDealGreenPercentage = (deals) => {
    var totalTasks = 0;
    var completedTasks = 0;
    for (var i = 0; i < deals.length; i++) {
      totalTasks += deals[i].totalTasks;
      completedTasks += deals[i].completedTaskGreen;
    }

    if (totalTasks == 0) return 0;
    else return (completedTasks * 100) / totalTasks;
  };

  return (
    <>
      <DragDropContext onDragEnd={state.onDragEnd}>
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="d-flex align-items-center">
              <div className="dropdown card-body mx-2">
                <h6
                  class="dropdown-toggle cursor-pointer card-subtitles"
                  data-toggle="dropdown"
                >
                  {filterData.WorkspaceAgentName}
                  <b class="caret"></b>
                </h6>
                {/* {accessType == 1 ? ( */}
                <ul class="dropdown-menu">
                  {workspaceDD?.map((curStatus, i) => {
                    return (
                      <li
                        key={curStatus.value}
                        onClick={() =>
                          handleWorkspaceDDChange(
                            curStatus.Value,
                            curStatus.Text
                          )
                        }
                      >
                        <a>{curStatus.Text}</a>
                      </li>
                    );
                  })}
                </ul>
                {/* ) : null} */}
              </div>
              <div className="dropdown mx-3">
                <span
                  class="dropdown-toggle text-light cursor-pointer"
                  data-toggle="dropdown"
                >
                  {" "}
                  pipeline: {filterData.WorkspacePipeline}
                  <b class="caret"></b>
                </span>
                <ul class="dropdown-menu">
                  {pipelineDD?.map((curElem, i) => {
                    return (
                      <li
                        key={curElem.Value}
                        onClick={() =>
                          handlePipelineChange(curElem.Value, curElem.Text)
                        }
                      >
                        <a>{curElem.Text}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="mx-3">
                <div
                  className="form-inline form-group"
                  style={{ marginBottom: 0 }}
                >
                  <div className="element">
                    <div className="truncate">
                      <label>
                        <i className="fa fa-filter" aria-hidden="true"></i>
                        &nbsp;
                      </label>
                      <a
                        className="filter-text"
                        onClick={() => loopFilterOpen("Pipeline")}
                      >
                        <span>
                          <b>Filter: </b>
                          {filterData.getPipelineFilterStringFull()}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mx-3">
                <div>
                  <div
                    class="form-group has-feedback"
                    style={{ marginBottom: 0 }}
                  >
                    <span
                      class="fa fa-search form-control-feedback"
                      style={{ marginTop: 6 }}
                    ></span>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      name="search-input"
                      placeholder="Search"
                      id="search-input"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {state?.isDataLoading || isLoading ? (
          <AjaxLoader />
        ) : (
          <div className="row mt-2">
            <div className={isDragCardScrollable(dragCardsHeight, pipelineBoxHeight) ? "drag_cards d-flex" : "dragCardsHiddenScrollbar d-flex"} ref={dragCardsRef} >
              {pipelineDisplayData &&
                pipelineDisplayData.map((curStage, i) => {

                  const {
                    completedStageTaskGreen = 0,
                    completedStageTaskRed = 0,
                    completedStageTaskGrey = 0,
                    deals = [],
                    stageName = "",
                    stageId = ""
                  } = curStage

                  return (
                    <div className="card_item" key={i} style={{ height: stageMaxHeight + "px" }}>
                      <div className="stick_header_pipeline" style={{ marginLeft: "10px" }}>
                        <div className="d-flex p-1 mx-3">
                          <OMSTooltip
                            infoText={
                              <>
                                <div className="row">
                                  <div className="col">
                                    <span
                                      style={{ fontWeight: "bold" }}
                                      className="text-success text-center"
                                    >
                                      Completed Tasks:{" "}
                                      {completedStageTaskGreen}
                                    </span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <span
                                      style={{ fontWeight: "bold" }}
                                      className="text-danger text-center "
                                    >
                                      Overdue Tasks:{" "}
                                      {completedStageTaskRed}
                                    </span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <span
                                      style={{ fontWeight: "bold" }}
                                      className="text-dark text-center"
                                    >
                                      Outstanding Tasks:{" "}
                                      {completedStageTaskGrey}
                                    </span>
                                  </div>
                                </div>
                              </>
                            }
                          >
                            <div
                              className="black_circle"
                              style={{ marginLeft: 8 }}
                            >
                              <Progressbar
                                redCounts={getAllDealRedPercentage(
                                  deals
                                )}
                                greenCounts={getAllDealGreenPercentage(
                                  deals
                                )}
                              />
                            </div>
                          </OMSTooltip>
                          <div className="d-flex flex-column mx-2">
                            <span>
                              {" "}
                              <strong>{stageName}</strong>{" "}
                            </span>
                            <span className="small_caption">
                              {`${deals.length
                                } Transactions | ${currencySymbol}${getAmountFormat(
                                  getAllDealAmount(deals, "P")
                                )} Sales | ${currencySymbol}
                            ${getAmountFormat(
                                  getAllDealAmount(deals, "G")
                                )} GC
                      `}
                            </span>
                          </div>
                        </div>
                      </div>

                      <Droppable droppableId={stageId}>
                        {(provided, snapshot) => (
                          <div className="container-drag" >
                            <div
                              key={i}
                              className={`mt-4 drag_drop_container p-2 ml-3 ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
                              ref={provided.innerRef}
                              style={{ minHeight: (stageMaxHeight + 100) + "px" }}
                              {...provided.droppableProps}
                            >
                              <div id={"drag_drop_container_" + i}
                                className="dragDropInnerContainer">
                                {deals?.map((curElem, i) => {
                                  const {
                                    dealID = "",
                                    completedTaskRed = 0,
                                    completedTaskGreen = 0,
                                    completedTaskGrey = 0,
                                    totalTasks = 0,
                                    registrationDate = "",
                                    dealName = "",
                                    purchaseAmount = 0,
                                    grossNettCommision = 0,
                                    internalDealTags = []

                                  } = curElem;
                                  return (
                                    <Draggable
                                      key={dealID}
                                      draggableId={dealID}
                                      index={i}
                                    >
                                      {(provided) => (
                                        <div
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          ref={provided.innerRef}
                                          className="card_element mt-2 p-2"
                                        >
                                          <div className="d-flex align-items-center">
                                            <div
                                              className=""
                                              style={{ marginLeft: "3.5px" }}
                                            >
                                              <div className="black_circle">
                                                <OMSTooltip
                                                  infoText={
                                                    <>
                                                      <div className="row">
                                                        <div className="col">
                                                          <span
                                                            style={{
                                                              fontWeight: "bold",
                                                            }}
                                                            className="text-success text-center"
                                                          >
                                                            Completed Tasks:{" "}
                                                            {
                                                              completedTaskGreen
                                                            }
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col">
                                                          <span
                                                            style={{
                                                              fontWeight: "bold",
                                                            }}
                                                            className="text-danger text-center "
                                                          >
                                                            Overdue Tasks:{" "}
                                                            {
                                                              completedTaskRed
                                                            }
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col">
                                                          <span
                                                            style={{
                                                              fontWeight: "bold",
                                                            }}
                                                            className="text-dark text-center"
                                                          >
                                                            Outstanding Tasks:{" "}
                                                            {
                                                              completedTaskGrey
                                                            }
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </>
                                                  }
                                                >
                                                  <Progressbar
                                                    redCounts={
                                                      totalTasks == 0
                                                        ? 0
                                                        : (completedTaskRed *
                                                          100) /
                                                        totalTasks
                                                    }
                                                    greenCounts={
                                                      totalTasks == 0
                                                        ? 0
                                                        : (completedTaskGreen *
                                                          100) /
                                                        totalTasks
                                                    }
                                                  />
                                                </OMSTooltip>
                                              </div>
                                            </div>
                                            <div className="mx-2 p-2 d-flex flex-column">
                                              <div className="">
                                                <span
                                                  className="element_link"
                                                  onClick={() => {
                                                    history.push(
                                                      `/deal/saveDeal?ID=${curElem.dealID}`
                                                    );
                                                  }}
                                                >
                                                  {dealName}
                                                </span>
                                              </div>
                                              {registrationDate ? (
                                                <div
                                                  className=""
                                                  style={{
                                                    fontSize: "12px",
                                                    marginTop: 4,
                                                    marginBottom: 2,
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-calendar"
                                                    style={{ fontSize: "12px" }}
                                                  ></i>{" "}
                                                  <span>
                                                    Expected Registration:{" "}
                                                    {registrationDate}
                                                  </span>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              <div className="tags tag-container">
                                                <span className="tag1 bg-oms1">{`${currencySymbol} ${getAmountFormat(
                                                  purchaseAmount
                                                )}`}</span>
                                                <span className="tag2 bg-oms">{`${currencySymbol} ${getAmountFormat(
                                                  grossNettCommision
                                                )} GC`}</span>
                                                {internalDealTags.map((dealTag, index) => {
                                                  return (
                                                    <>
                                                      <span
                                                        key={index}
                                                        className={"tag2 bg-oms-internal"}
                                                      >
                                                        {dealTag}
                                                      </span>
                                                    </>
                                                  )
                                                })}


                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </Droppable>
                    </div>
                  );
                })
              }
            </div>
          </div>
        )}
      </DragDropContext>
    </>
  );
};

export default PipelineSales;
