import React, { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { DataTableCompDynamicT } from "../../../app/common/common-components/OMSDatatables";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

const TaskGrid = ({ actions, filterData, loopFilterOpen }) => {

  const history = useHistory();
  const [extraParams, setExtraParams] = useState(JSON.stringify(filterData));

  useEffect(() => {
    //console.log("useEffect >> TaskGrid");
    //console.log(filterData);
    setExtraParams(JSON.stringify(filterData));
  }, [filterData]);

  const loadGrid = (requestParams) => {
    //console.log("loadGrid >> TaskGrid");
    return apiAgent.Deal.taskDynamicAjax(
      requestParams,
      filterData.AgentId,
      filterData.PipelineId,
      filterData.WorkspaceAgentId,
      filterData.WorkspacePipelineId,
      filterData.PropertyTypes,
      filterData.Areas,
      filterData.RegistrationStartFrom,
      filterData.RegistrationEndTo,
      filterData.NeedUpdate,
      filterData.StageId,
      filterData.InternalDealTags
    );
  };

  const handleRowClick = (data) => {
    //console.log(data);
    history.push('/Deal/saveDeal?ID=' + data.dealId);
  };

  return (
    <>
      <div className="row mt-2">
        <div className="col-md-11 col-sm-12">
          <div className="panel panel-default panel-table oms-box">
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="form-inline form-group">
                    <div className="element">
                      <div className="truncate">
                        <label>
                          <i className="fa fa-filter" aria-hidden="true"></i>
                          &nbsp;
                        </label>
                        <a className="filter-text" onClick={() => loopFilterOpen()}>
                          <span>
                            <b>Filter: </b>
                            {filterData.getFilterStringFull()}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">

                </div>
              </div>
            </div>
            <div className="panel-body">
              <div>
                <DataTableCompDynamicT id="dealTable" onRowClick={handleRowClick} makeAjaxRequest={loadGrid} ExtraParams={extraParams} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaskGrid;