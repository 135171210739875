import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { OMSImageUpload } from "../../../app/common/common-components/OMSImageUpload";

function PersonalTab({ contact, customPersonalTabValues, setCustomPersonalTabValues }: any) {
  const getOfficeDefaultTags = (selectedItems: any) => {
    var items = [];
    var selectedItemsL = selectedItems.map((I: string) => I.toLowerCase());
    var linkedListingJSON = contact!.linkedListingJSON ? JSON.parse(contact!.linkedListingJSON) : [];
    var linkedListingTagJSON = contact!.linkedListingJSON ? JSON.parse(contact!.linkedListingJSON).map((I: any) => I.ContactType) : [];
    var linkedListingIncludedTagJSON = [];

    for (var i = 0; i < contact!.officeTags!.length; i++) {
      var item = {
        name: contact!.officeTags![i].name,
        color: contact!.officeTags![i].color,
        selected: false,
        linked: false,
        linkedobj: {},
      };
      if (selectedItemsL.indexOf(contact!.officeTags![i].name.toLowerCase()) > -1) item.selected = true;

      var index = linkedListingTagJSON.indexOf(contact!.officeTags![i].name.toLowerCase());
      if(index = -1) index = linkedListingTagJSON.indexOf(contact!.officeTags![i].name);
      if (index > -1) {
        item.linked = true;
        item.linkedobj = linkedListingJSON[index];
        linkedListingIncludedTagJSON.push(linkedListingTagJSON[index]);
      }
      items.push(item);
    }

    if (linkedListingTagJSON.length != linkedListingIncludedTagJSON.length) {
      for (var i = 0; i < linkedListingTagJSON.length; i++) {
        if (linkedListingIncludedTagJSON.indexOf(linkedListingTagJSON[i]) == -1) {
          var item2 = {
            name: linkedListingTagJSON[i],
            color: "#777",
            selected: false,
            linked: true,
            linkedobj: linkedListingJSON[i],
          };
          items.push(item2);
        }
      }
    }

    items.sort((I: any) => I.linked);
    items.reverse();

    return items;
  };
  const getUserDefinedTags = (selectedItems: any) => {
    var items = [];
    var selectedItemsL = selectedItems.map((I: string) => I.toLowerCase());
    for (var i = 0; i < contact!.userTags!.length; i++) {
      if (selectedItemsL.indexOf(contact!.userTags![i].name.toLowerCase()) > -1)
        items.push({
          name: contact!.userTags![i].name,
          color: contact!.userTags![i].color,
          selected: true,
        });
      else
        items.push({
          name: contact!.userTags![i].name,
          color: contact!.userTags![i].color,
          selected: false,
        });
    }
    return items;
  };
  const getDefaultPipelineDropdown=(DDValues:any)=>{
    const items = [];
    for (const pipelineElement of DDValues) {  
      const item = {
        name: pipelineElement.pipeline_name,
        value:pipelineElement.pipeline_id,
        stage:pipelineElement.stages
      };
      items.push(item);
    }    
    return items;  
  }
  const getDefaultMilestoneDropdown=(DDValues:any,pipelineId:string)=>{
   
    const items = [];
    if (!Array.isArray(DDValues)) {
      return []; // Return an empty array if DDValues is not an array
  }
  const Milestone = DDValues.find(s=>s.pipeline_id===pipelineId)?.stages||[];
   
    for (const milestoneElement of Milestone) { 

       const item = {
         name: milestoneElement.stage_name,
         value:milestoneElement.stage_id         
       };
      items.push(item);
    }    
    return items;  
  }
  const getFormattedBirthDate = () => {
    // if (contact.contactID == "") {
    //   return null;
    // } else {
      const date = contact.birthDate;
      let format = "DD-MM-yyyy";
      if (date) {
        let isoBirthDateString = moment(date, format).toISOString();
        return new Date(isoBirthDateString);
      } else return null;
    //}
    //return new Date();
  };

  const [leadSource, setLeadSource] = useState(contact!.leadSource);
  const [status, setStatus] = useState(contact!.status);

  const [tags, setTags] = useState(contact!.tags);
  const [contactType, setContactType] = useState(customPersonalTabValues!.contactType);
  const [gender, setGender]=  useState(customPersonalTabValues!.gender);
  const [sharing, setSharing] = useState(customPersonalTabValues.sharing);
  const [title, setTitle] = useState(contact!.title);
  const [firstName, setFirstName] = useState(customPersonalTabValues!.firstName);
  const [lastName, setLastName] = useState(customPersonalTabValues!.lastName);
  const [companyName, setCompanyName] = useState(customPersonalTabValues!.companyName);
  const [nationalIDNumber, setNationalIDNumber] = useState(customPersonalTabValues!.nationalIDNumber);
 // const [pipelineId,setPipelineId] = useState(contact?.pipelineId);
 // const [milestonneId,setMilestoneId] = useState(contact?.stageId);
  

  //moment(contact!.birthDate).format("DD-MM-yyyy")
  const [birthDate, setBirthDate] = useState(getFormattedBirthDate);

  const [photoUrl, setPhotoUrl] = useState(contact!.photoUrl);

  const [photoUpload, setPhotoUpload] = useState<string>("");

  const [officeDefaultTags, setOfficeDefaultTags] = useState(getOfficeDefaultTags(contact!.tags));
  const [userDefinedTags, setUserDefinedTags] = useState(getUserDefinedTags(contact!.tags));
  const [pipelineDD, setPipelineDD] = useState(getDefaultPipelineDropdown(contact?.pipelineDD));
  const [milestoneDD, setMilestoneDD] = useState(getDefaultMilestoneDropdown(contact?.pipelineDD,contact?.pipelineId));
  
  const handleCustomValuesChange = (name: string, value: any) => {
    var tempCustomPersonalTabValues = customPersonalTabValues;
    switch (name) {
      case "leadSource":
        tempCustomPersonalTabValues!.leadSource = value;
        break;
      case "sharing":
        tempCustomPersonalTabValues!.sharing = value;
        break;
      case "title":
        tempCustomPersonalTabValues!.title = value;
        break;
      case "birthDate":
        tempCustomPersonalTabValues!.birthDate = value;
        break;
      case "status":
        tempCustomPersonalTabValues!.status = value;
        setStatus(value);
        break;

      case "gender":
        tempCustomPersonalTabValues!.gender=value;
        break;
      case "contactType":
        tempCustomPersonalTabValues!.contactType=value;
        break;
      case "firstName":
        tempCustomPersonalTabValues!.firstName = value;
        setFirstName(value);
        break;
      case "lastName":
        tempCustomPersonalTabValues!.lastName = value;
        setLastName(value);        
        break;
      case "companyName":
        tempCustomPersonalTabValues!.companyName = value;
        setCompanyName(value);
        break;
      case "nationalIDNumber":
        tempCustomPersonalTabValues!.nationalIDNumber = value;
        setNationalIDNumber(value);
        break;
      case "photoUpload":
        tempCustomPersonalTabValues!.photoUpload = value;
        break;
      case "pipeline":
        tempCustomPersonalTabValues!.pipelineId = value;
        const Milestone = getDefaultMilestoneDropdown(contact?.pipelineDD,value);
        tempCustomPersonalTabValues!.stageId = Milestone[0].value; 

        setMilestoneDD(Milestone);        
      break;
       case "milestone":
        tempCustomPersonalTabValues!.stageId = value;        
      break;
      default:
    }
    setCustomPersonalTabValues(tempCustomPersonalTabValues);
  };
  const handleContactTypeChange = (e: any) => {
    var contactType="1";
    if(e.target.value=="on" && e.target.id=="rbtnCompany")
    {
      contactType="2";
    }
    handleCustomValuesChange("contactType", contactType);
    setContactType(contactType);
  };

  const handleGenderChange = (e: any) => {
    var gender = "1";
    if (e.target.value == "on" && e.target.id == "rbtnMale") {
      gender = "2";
    }
    handleCustomValuesChange("gender", gender);
    setGender(gender);
  };

  const handleDropdownChange = (e: any) => {
    handleCustomValuesChange(e.name, e.newValue);
  };

  const handleInputTextChange = (e: any) => {
    handleCustomValuesChange(e.target.name, e.target.value);
  };

  const handleDateChange = (e: any) => {
    //handleCustomValuesChange(e.name, e.newValue);
  };

  const handleCheckboxChange = (e: any) => {
    handleCustomValuesChange(e.target.name, e.target.value);
  };

  const handleSliderChange = (e: any) => {
    //handleCustomValuesChange(e.name, e.newValue);
  };

  const handleSourceDDChange = (e: any) => {
    setLeadSource(e.newValue);
  };

  const handleTitleChange = (e: any) => {
    handleCustomValuesChange("title", e.target.value);
    setTitle(e.target.value);
  };

  const handleSharingChanged = (e: any) => {
    var Sharing = customPersonalTabValues.sharing === 1 ? 2 : 1;
    handleCustomValuesChange("sharing", Sharing);
    setSharing(Sharing);
  };

  const handleStatusChanged = (e: any) => {
    var Status = status === 1 ? 2 : 1;
    //if (status == 1) Status = 2;
    handleCustomValuesChange("status", Status);
    setStatus(Status);
  };

  const handleTagsClick = (name: any) => {
    var selectedTags = tags;
    var index = selectedTags.indexOf(name);
    if (index == -1) index = selectedTags.indexOf(name.toLowerCase());
    if (index < 0) {
      selectedTags.push(name);
    } else {
      selectedTags.splice(index, 1);
    }
    setTags(selectedTags);
    setOfficeDefaultTags(getOfficeDefaultTags(selectedTags));
    setUserDefinedTags(getUserDefinedTags(selectedTags));
  };

  // const handleContactTypeChanged = (e: any) => {
  //   setContactType(e.target.value.toString());
  // };

  const OnCropSubmit = (d: any) => {
    setPhotoUpload(d);
    handleCustomValuesChange("photoUpload", d);
  };

  const contactTypeOptions = [
    { id: "rbtnIndividual", text: "Individual", value: 1 },
    { id: "rbtnCompany", text: "Company", value: 2 },
  ];

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="ContactType">Contact type</label>
          <div className="">
            {officeDefaultTags.map(function (item: any) {
              return item.selected ? (
                <span key={item.name} className="btn btn-tag-nonclick" style={{ backgroundColor: item.color }}>
                  <i className="fa fa-check" aria-hidden="true"></i>
                  {item.name}
                </span>
              ) : null;
            })}
            <span
              data-toggle="modal"
              style={{
                cursor: "pointer",
                display: "inline-block",
                verticalAlign: "middle",
                marginTop: 12,
              }}
              data-target="#selectTagModel"
              title="Tags"
            >
              <i className="fa fa-tags" style={{ fontSize: 28 }} aria-hidden="true"></i>
            </span>
            {tags.length == 0 ? (
              <span
                style={{
                  marginLeft: 10,
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginTop: 12,
                }}
              >
                Select contact type
              </span>
            ) : null}
          </div>
          <div className="">
            {userDefinedTags.map(function (item: any) {
              return item.selected ? (
                <span key={item.name} className="btn btn-my-tag-nonclick my-tag" style={{ backgroundColor: item.color }}>
                  <i className="fa fa-check" aria-hidden="true"></i>
                  {item.name}
                </span>
              ) : null;
            })}
          </div>
        </div>
        <div className="form-group">
          <div className="form-inline" style={{ marginBottom: 10 }}>
            {/* <FormikControl control="radio" options={contactTypeOptions} name="contactType" value={contactType} /> */}
            <label className="radio-inline">
              <input type="radio" id="rbtnIndividual" name="rbtnIndividual" checked={contactType === "1"} onChange={handleContactTypeChange} />
              Individual
              {/* <Field
                type="radio"
                id="rbtnIndividual"
                name="contactType"
                value="1"
                onClick={() => {
                  setContactType("1");
                }}
              /> */}
              {/* Individual */}
            </label>
            <label className="radio-inline">
              <input type="radio" id="rbtnCompany" name="rbtnCompany" checked={contactType === "2"} onChange={handleContactTypeChange} />
              Company
              {/* <Field
                type="radio"
                id="rbtnCompany"
                name="contactType"
                value="2"
                onClick={() => {
                  setContactType("2");
                }}
              /> */}
              {/* Company */}
            </label>
          </div>
        </div>
        <div className="form-group">
          <input
            type="checkbox"
            id="sharing"
            name="sharing"
            value={sharing}
            checked={sharing === 2}
            onChange={handleSharingChanged}
            //onChange={handleCheckboxChange}
            // onChange=(e: any) => {
            //   var Sharing = sharing === 1 ? 2 : 1;
            //   setSharing(Sharing);
            //onChange={handleSharingChanged}
          />
          {/* <Field
                                  type="checkbox"
                                  name="sharing"
                                  onChange={handleSharingChanged}
                                  checked={sharing === 2}
                                /> */}{" "}
          Shared with office
        </div>
        <div className="form-group">
          <label htmlFor="leadSource">Source</label>

          <OMSDropdown
            id="leadSource"
            name="leadSource"
            options={contact!.contactSourceTypes!}
            valueField="name"
            labelField="name"
            value={customPersonalTabValues.leadSource}
            //onChange={handleSourceDDChange}
            onChange={handleDropdownChange}
          />
        </div>
        <div className="" style={{
    display: "flex",   
    gap: "30px",
  }}>
        <div className="form-group">
          <label htmlFor="pipeline">Pipeline</label>
          <OMSDropdown
            id="pipeline"
            name="pipeline"
            options={pipelineDD||[]}
            valueField="value"
            labelField="name"
            value={customPersonalTabValues?.pipelineId}
            //onChange={handleSourceDDChange}
            onChange={handleDropdownChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="milestone">Milestone</label>
          <OMSDropdown
            id="milestone"
            name="milestone"
            options={milestoneDD||[]}
            valueField="value"
            labelField="name"
            value={customPersonalTabValues?.stageId}
            //onChange={handleSourceDDChange}
            onChange={handleDropdownChange}
          />
        </div>
         </div>
        
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <select id="Title" name="Title" className="form-control" style={{ maxWidth: "80px" }} value={title} onChange={handleTitleChange}>
            <option value="Mr">Mr.</option>
            <option value="Ms">Ms.</option>
            <option value="Dr">Dr.</option>
            <option value="Prof">Prof.</option>
          </select>
          {/* <Field as="select" name="title" className="form-control" value={title} style={{ maxWidth: "80px" }} onChange={handleTitleChange}>
            <option value="Mr">Mr.</option>
            <option value="Ms">Ms.</option>
            <option value="Dr">Dr.</option>
            <option value="Prof">Prof.</option>
          </Field> */}
        </div>
        <div className="form-group">
          <label htmlFor="FirstName">First name</label>
          <input id="FirstName" name="firstName" type="text" className={contactType === "1" ? "form-control required-control" : "form-control"} value={firstName} onChange={handleInputTextChange} />
          {/* <Field type="text" name="firstName" className={contactType === "1" ? "form-control required-control" : "form-control"} placeholder="First Name" /> */}
        </div>
        <div className="form-group">
          <label htmlFor="LastName">Last name</label>
          <input id="LastName" name="lastName" type="text" className={contactType === "1" ? "form-control required-control" : "form-control"} value={lastName} onChange={handleInputTextChange} />
          {/* <Field type="text" name="lastName" className={contactType === "1" ? "form-control required-control" : "form-control"} placeholder="Last Name" /> */}
        </div>
        <div className="form-group">
          <label htmlFor="CompanyName">Company name</label>
          <input id="CompanyName" name="companyName" type="text" className={contactType === "2" ? "form-control required-control" : "form-control"} value={companyName} onChange={handleInputTextChange} />
          {/* <Field type="text" name="companyName" className={contactType === "2" ? "form-control required-control" : "form-control"} placeholder="Company Name" /> */}
        </div>
        
        <div className="form-group">
          <label htmlFor="NationalIDNumber">National Identification Number</label>
          <input id="NationalIDNumber" name="nationalIDNumber" type="text" className="form-control" value={nationalIDNumber} onChange={handleInputTextChange} />
          {/* <Field type="text" name="nationalIDNumber" className="form-control" placeholder="NationalID Number" /> */}
        </div>
        <div className="form-group">
          <label htmlFor="BirthDate">Date of birth</label>
          <div className="form-inline">
            {/* <input id="BirthDate" name="BirthDate" type="text" className="form-control datepickerx" value={birthDate} onChange={handleBirthDateChange} /> */}
            {/* <Field
                                                                        type="text"
                                                                        name="birthDate"
                                                                        className="form-control datepickerx"
                                                                        placeholder="Birth date"
                                                                    /> */}
            {/* <DatePickerFormikField
              name="birthDate"
              wrapperClassName="datepicker"
              dateFormat="dd-MM-yyyy"
              className="form-control"
              callBack={setBirthDate}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            ></DatePickerFormikField> */}
            <DatePicker
              name="birthDate"
              selected={birthDate}
              wrapperClassName="datepicker"
              dateFormat="dd-MM-yyyy"
              className="form-control"
              onChange={(date) => {
                setBirthDate(date);
                handleCustomValuesChange("birthDate", date == null ? "" : moment(date).format("DD-MM-YYYY"));
              }}
              
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              isClearable={true}
            ></DatePicker>
          </div>
        </div>
        {/* <div
                                id="picker-container"
                                style={{ position: "relative" }}
                              ></div> */}
        <div className="form-group">
          <label htmlFor="Gender">Gender</label>
          <div className="form-inline" style={{ marginBottom: 10 }}>
            <label className="radio-inline">
              <input type="radio" id="rbtnMale" name="rbtnGender" checked={gender === "2"} onChange={handleGenderChange} />Male
              {/* <Field type="radio" id="rbtnMale" name="gender" value="2" /> */}
              {/* Male */}
            </label>
            <label className="radio-inline">
              <input type="radio" id="rbtnFemale" name="rbtnGender" checked={gender === "1"} onChange={handleGenderChange} />Female
              {/* <Field type="radio" id="rbtnFemale" name="gender" value="1" /> */}
              {/* Female */}
            </label>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <OMSImageUpload src={photoUrl} width={160} height={200} compressor={true} onCropSubmit={OnCropSubmit} />
        </div>
        <div className="form-group">
          <label className="switch">
            <input type="checkbox" id="Status" name="Status" checked={status === 1} onChange={handleStatusChanged} />
            {/* <Field type="checkbox" name="status" checked={status === 1} onChange={handleStatusChanged} /> */}
            <div className="slider round" />
            <span
              style={{
                marginLeft: 70,
                display: "block",
                width: 120,
                marginTop: 7,
              }}
            >
              Status: {status === 1 ? "Active" : "Inactive"}
            </span>
          </label>
        </div>
      </div>
      <div className="modal fade" id="selectTagModel" tabIndex={-1} aria-labelledby="selectTagModelLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
              <h4 className="modal-title" id="selectTagModelLabel">
                Tags
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group col-md-12">
                    {officeDefaultTags.map(function (item: any) {
                      return item.selected ? (
                        <span key={item.name} className="btn btn-tag-click" style={{ backgroundColor: item.color }} onClick={() => handleTagsClick(item.name)}>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          {item.name}
                        </span>
                      ) : (
                        //<span key={item.name} className="btn btn-tag-click" style={{ backgroundColor: '#BDBD00' }} onClick={() => handleTagsClick(item.name)}><i className="fa fa-check" aria-hidden="true"></i>{item.name}</span> :
                        <span
                          key={item.name}
                          className="btn btn-tag-click"
                          style={{
                            backgroundColor: "#ccc",
                            color: "#333",
                          }}
                          onClick={() => handleTagsClick(item.name)}
                        >
                          {item.name}
                        </span>
                      );
                    })}
                  </div>
                  <div className="form-group col-md-12">
                    {userDefinedTags.map(function (item: any) {
                      return item.selected ? (
                        <span
                          key={item.name}
                          className="btn btn-my-tag-click"
                          style={{ backgroundColor: item.color }}
                          onClick={() => handleTagsClick(item.name)}
                        >
                          <i className="fa fa-check" aria-hidden="true"></i>
                          {item.name}
                        </span>
                      ) : (
                        <span
                          key={item.name}
                          className="btn btn-my-tag-click"
                          style={{
                            backgroundColor: "#ccc",
                            color: "#333",
                          }}
                          onClick={() => handleTagsClick(item.name)}
                        >
                          {item.name}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalTab;
