import { useRef, useState } from "react";
import { OMSMultiselectTagDropdown2 } from "../../../app/common/common-components/OMSMultiselectTagDropdown";
import { useHistory } from "react-router";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";

const ContactFilter = ({
    actions = [],
    contactType = 0,
    officeTags = [],
    onChangeContactViewType = () => { },
    onChangeTags = () => { },
    onChangeViewType = () => { },
    onClickSeach = () => { },
    sourceTypesTag = [],
    userTags = [],
    viewType = 0,
}) => {
    const history = useHistory();
    const [tags, setTags] = useState([]);
    const [sources, setSources] = useState([]);
    const refSearchBox = useRef();


    return (
        <>
            <div className="filter-container">

                <div className="contact-view-type-control">
                    <div className="dropdown mx-2">
                        <h4
                            class="dropdown-toggle cursor-pointer card-subtitles"
                            data-toggle="dropdown"
                            style={{ fontWeight: "bolder" }}
                        >
                            {contactType == 0 ? "My Contacts" : "All Contacts"}
                            <b class="caret"></b>
                        </h4>

                        <ul class="dropdown-menu">
                            <li
                                onClick={() =>
                                    onChangeContactViewType(0)
                                }
                            >
                                <a>My Contacts</a>
                            </li>
                            <li
                                onClick={() =>
                                    onChangeContactViewType(1)
                                }
                            >
                                <a>All Contacts</a>
                            </li>
                        </ul>

                    </div>


                </div>

                <div
                    className="tag-filter-control mt-5"
                    style={{
                        marginRight: "10px",
                    }}
                >
                    <OMSMultiselectTagDropdown2
                        array1={officeTags}
                        array2={userTags}
                        array3={sourceTypesTag}
                        id="tag-source"
                        name="tag-source"
                        onChange={onChangeTags}
                        svalue={sources}
                        value={tags}
                    />
                </div>

                <div className="contact-view-type-control">
                    <div className="dropdown mx-2">
                        <h4
                            class="dropdown-toggle cursor-pointer card-subtitles"
                            data-toggle="dropdown"
                            style={{
                                fontWeight: "200",
                                width: "130px"
                            }}
                        >
                            {viewType == 0 && "List"}
                            {viewType == 1 && "Card summery"}
                            {viewType == 2 && "Card full"}

                            <b class="caret"></b>
                        </h4>

                        <ul class="dropdown-menu">
                            <li
                                onClick={() =>
                                    onChangeViewType(0)
                                }
                            >
                                <a>List</a>
                            </li>
                            <li
                                onClick={() =>
                                    onChangeViewType(1)
                                }
                            >
                                <a>Card summary</a>
                            </li>
                            <li
                                onClick={() =>
                                    onChangeViewType(2)
                                }
                            >
                                <a>Card full</a>
                            </li>
                        </ul>

                    </div>


                </div>
                <div class="search-box-inline">
                    <input type="text" ref={refSearchBox} placeholder="Search..." class="search-input-inline" />
                    <span class="search-button-inline" onClick={() => onClickSeach(refSearchBox.current.value)}>
                        <i class="fa fa-search"></i>
                    </span>
                </div>
                <div className="add-new-button mt-5">
                    {actions.indexOf("create") != -1 && (
                        <OMSButtonStyleL
                            disabled={false}
                            onClick={() => history.push('/Contacts/SaveContact')}
                            processing={false}
                            type="button"
                            style={{
                                margin: "",
                            }}
                            ui_icon=""
                            ui_processing_text=""
                            ui_text="add contact"
                            ui_type="create"

                        />
                    )}
                </div>
            </div>
        </>








    );
}

export default ContactFilter;