import { useEffect, useRef, useState } from "react";
import { contactDateFormat2 } from "../../../app/common/common-functions/functions";
import { useHistory } from "react-router";
import { useStore } from "../../../app/stores/store";

const ContactCard = ({
    provided,
    snapshot,
    viewType,
    contactItem = {},
    tagColorFormatter = () => { },
    onWhatsappClick = () => { },
    onClickSendEmail = () => { },
    onClickNewActivity = () => { }
}) => {
    const {
        userStore: { userSession, logout, logoutAs, isLoggedIn },
    } = useStore();
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const [activeModalId, setActiveModalId] = useState();
    const history = useHistory();
    const {
        companyName = "",
        contactID = "",
        contactPropertyId = "",
        dialingCode = "",
        diary = {},
        emailAddress = "",
        lastActivity = "",
        name = "",
        phoneNumber = "",
        requirementOrLead = "",
        requirementType = 0,
        tags = "",
    } = contactItem;

    const getDiaryTime = (diaryDate) => {
        if (!diaryDate) return "N/A";
        return diaryDate.split(" ")[1] + " " + diaryDate.split(" ")[2];
    }
    const getDiaryDate = (diaryDate) => {
        const [day, month, year, time, meridian] = diaryDate.split(/[-\s:]+/);
        const hours = meridian === "PM" && time !== "12" ? parseInt(time) + 12 : time === "12" && meridian === "AM" ? "00" : time;
        const formattedDateStr = `${year}-${month}-${day}T${hours}:00`;
        return contactDateFormat2(formattedDateStr);
    }
    const isCheckFieldsAvailable = (emailAddress, phoneNumber, diary) => {
        if (emailAddress || phoneNumber || (diary && Object.keys(diary).length > 0)) {
            return true;
        }
        return false; // Explicit return to ensure the function always returns a boolean
    };
    const leadRequirementFormatter = (str) => {
        let parts = str.split(" ");
        let formatString = <>
            <strong>{parts[0]}</strong>
            <span>{" " + parts.slice(1).join(" ")}</span>
        </>
        return formatString;
    }



    const toggleContextMenu = (event) => {
        event.preventDefault();
        setIsContextMenuOpen((prev) => !prev);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsContextMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const onClickNewDiary = () => {
        onClickNewActivity(contactID);
    }

    const handleClickExternalLink = () => {

        switch (requirementType) {
            case 1:
                history.push('/Contacts/SaveContact?ID=' + contactID);
                break;
            case 2:
                history.push('/Property/SaveProperty?ID=' + contactPropertyId);
                break;

            default:
                break;
        }

    }

    return (
        <>

            <div
                className="card"
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
            >
                <div className="card-header">

                    <div className="row">
                        <div className="col-md-12">
                            <div style={{
                                display: "inline-block",
                                width: "100%",
                            }}>
                                <span
                                    style={{
                                        cursor: "pointer",
                                        position: "relative"
                                    }}
                                >
                                    {/* {
                                        isCheckFieldsAvailable(emailAddress, phoneNumber, diary) && */}
                                        <i className="fa fa-ellipsis-v m-10 ellipsis-round" onClick={toggleContextMenu}></i>
                                    {/* }
                                    {
                                        !isCheckFieldsAvailable(emailAddress, phoneNumber, diary) &&
                                        <i className="fa fa-ellipsis-v m-10 ellipsis-round" style={{ color: "#6F6F6F", cursor: "not-allowed" }}></i>
                                    } */}

                                </span>
                                <span className="mt-5" onClick={() => history.push('/Contacts/SaveContact?ID=' + contactID)} style={{ marginLeft: "0px", cursor: "pointer" }}>
                                    {name} {" "}

                                </span>

                            </div>
                            {companyName && companyName != null && companyName != "" &&
                                <div style={{
                                    marginTop: "-10px",
                                    marginLeft: "35px"
                                }}>

                                    <span
                                        onClick={() => history.push('/Contacts/SaveContact?ID=' + contactID)}
                                        className="company"
                                        style={{
                                            cursor: "pointer"
                                        }}>
                                        {companyName}
                                    </span>
                                </div>
                            }
                            {isContextMenuOpen && (
                                <div
                                    ref={menuRef}
                                    className="context-menu"
                                    style={{
                                        position: 'absolute',
                                        top: '20px',
                                        right: '10',
                                        backgroundColor: '#f9f9f9',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        width: '270px',
                                        padding: '10px',
                                        boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
                                        zIndex: 10,
                                    }}
                                >
                                    {/* Contact Information */}

                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '10px',
                                            marginLeft: '20px',

                                        }}>
                                        {phoneNumber != "" && <>
                                            <div
                                                onClick={() => onWhatsappClick(dialingCode + phoneNumber)}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                <i
                                                    className="fa fa-whatsapp"
                                                    style={{
                                                        fontSize: "18px",
                                                        margin: "5px"
                                                    }}
                                                ></i>
                                                <span style={{ fontWeight: "normal" }}>{phoneNumber}</span>
                                            </div>
                                        </>}

                                    </div>
                                    {emailAddress &&

                                        <div onClick={() => onClickSendEmail(emailAddress)} style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '10px',
                                            marginLeft: '20px',
                                            cursor: "pointer",
                                            fontWeight: "normal"
                                        }}>
                                            <i

                                                className="fa fa-envelope-o"
                                                style={{
                                                    fontSize: "18px",
                                                    margin: "5px"
                                                }}
                                            ></i>
                                            <span>{emailAddress}</span>

                                        </div>
                                    }

                                    <div
                                        style={{
                                            fontWeight: "normal",
                                            display: 'flex', alignItems: 'center',
                                            marginBottom: '10px',
                                            cursor: "pointer",
                                            marginLeft: '10px',
                                        }}
                                        onClick={onClickNewDiary}
                                    >
                                        <i

                                            className="fa fa-calendar"
                                            style={{
                                                fontSize: "18px",
                                                margin: "5px"
                                            }}
                                        ></i>
                                        <span>New activity</span>
                                    </div>


                                    {/* Activity Note */}
                                    {
                                        diary !== null && Object.keys(diary).length > 0 &&
                                        <div
                                            style={{
                                                padding: '10px',
                                                backgroundColor: '#FFFBCC',
                                                borderRadius: '5px',
                                                cursor: "text"
                                            }}>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: '5px',

                                                }}>
                                                <i

                                                    className="fa fa-thumb-tack"
                                                    style={{
                                                        fontSize: "14px",
                                                        marginTop: "10px"
                                                    }}
                                                ></i>
                                                <span style={{
                                                    marginLeft: "20px",
                                                    fontSize: "14px"
                                                }}>
                                                    {
                                                        diary?.startDate
                                                        && diary?.startDate != null
                                                        && diary?.startDate != ""
                                                        && getDiaryTime(diary?.startDate)
                                                    }
                                                    {
                                                        diary?.endDate
                                                        && diary?.endDate != null
                                                        && diary?.endDate != ""
                                                        && " - " + getDiaryTime(diary?.endDate)
                                                    }
                                                </span>
                                            </div>
                                            <div style={{
                                                marginLeft: "28px"
                                            }}>
                                                <strong>Note:</strong>
                                                <p style={{
                                                    fontWeight: "normal",
                                                    fontSize: "14px"
                                                }}>
                                                    {
                                                        diary?.startDate
                                                        && diary?.startDate != null
                                                        && diary?.startDate != ""
                                                        && getDiaryDate(diary?.startDate)
                                                    }
                                                    {
                                                        diary?.endDate
                                                        && diary?.endDate != null
                                                        && diary?.endDate != ""
                                                        && " - " + getDiaryDate(diary?.endDate)
                                                    }
                                                </p>
                                                {
                                                    diary?.description != "" && <p style={{ fontWeight: "normal" }}>{diary?.description}</p>
                                                }

                                            </div>
                                        </div>
                                    }

                                </div>
                            )}
                        </div>
                    </div>

                </div>
                {viewType == 2 && (
                    <>
                        <div className="card-body">
                            <div style={{
                                display: "flex",
                                marginTop: "10px"
                            }}>
                                {
                                    diary === null && lastActivity !== "" && (
                                        <>
                                            <i title="Last contact time" className="fa fa-clock-o ml-5 mr-5" aria-hidden="true" style={{ cursor: "default" }}></i>
                                            <p>{lastActivity}</p>
                                        </>)
                                }
                                {
                                    diary !== null && Object.keys(diary).length > 0 && (
                                        <>
                                            <i className="fa fa-calendar ml-5 mr-5" aria-hidden="true"></i>
                                            <p>{diary.startDate}</p>
                                        </>)
                                }

                            </div>
                        </div>

                        {/* Labels moved outside card-body */}
                        <div className="card-labels">
                            {tagColorFormatter(tags)}
                        </div>
                        {requirementOrLead && requirementOrLead !== "" &&
                            <div className="card-footer">
                                <p>
                                    <a onClick={handleClickExternalLink}>
                                        <i class="fa fa-external-link" style={{ color: "black", cursor: "pointer", fontSize: "14px", marginRight: "5px" }} aria-hidden="true"></i>
                                    </a>
                                    {leadRequirementFormatter(requirementOrLead)}

                                </p>
                            </div>
                        }
                    </>
                )}
            </div>




        </>);
}

export default ContactCard;